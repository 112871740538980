import React from "react";

function Dot({ positon, timings, size, style1 }) {
  return (
    <div hidden={true}>
      {/* <span
        className={`edot edot${positon} edotsize${size} position-absolute rounded-circle bg-warning dot-custom ${style1} wow bounceIn
            data-wow-delay=${timings}`}
      /> */}
    </div>
  );
}

export default Dot;
