import React from "react";
import Assets from "../../../components/Assets";

function Story(props) {
  return (
    <section>
        <article className="storyBlock bg-dark pt-7 pb-30 pt-md-12 pb-md-7 pt-lg-17 pb-lg-13 pt-xl-23 pb-xl-20">
          <header className="text-center mb-15">
            <h2 className="text-capitalize">
              <span
                className="d-block wow bounceInUp"
                data-wow-delay="0.6s"
                style={{ color: "#545454", opacity: 1, fontSize: "20px" }}
              >
              </span>
            </h2>
          </header>
          <div id="journey" className="mt-n43 pt-43">
            <div className="m-10 p-10 mr-22">
            <div className="d-flex justify-content-between align-items-start">
              <h5>Watch Cultural Program Live</h5>
              {
                <iframe
                  width="500px"
                  height="400px"
                  src="https://www.youtube.com/embed/b8VNTSD74Lc?si=6aanm61OlwFxd1Wd"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              }
            </div>
            <div className="d-flex justify-content-between align-items-start">
              {
                <iframe
                  width="500px"
                  height="400px"
                  src="https://www.youtube.com/embed/b8VNTSD74Lc?si=6aanm61OlwFxd1Wd"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              }
              <h5>Watch Yuva Dasara Live</h5>
            </div>
          </div>
            <div
              className="row align-items-md-center"
              style={{ paddingRight: "140px" }}
            >
              <div className="col-12 col-md-6">
                <div className="extraWrap pl-5 pl-xl-0 ml-xl-n4">
                  <ul className="list-unstyled position-relative pt-8 mb-0">
                    <li>
                      <div
                        className="elStoryColumn position-relative wow bounceInUp"
                        data-wow-delay="1s"
                      >
                        <div className="descrWrap">
                          <h1
                            className="escHeading font-weight-bold mb-4"
                            style={{ color: "#3F89BD" }}
                          >
                            World Famous Mysore Dasara
                          </h1>
                          <p style={{ color: "#637B8C" }}>
                            Powered by FusionMinds
                          </p>
                          <p style={{ color: "#B4B3B3" }}>
                            Government of Karnataka along with the respective
                            departments & FusionMinds started to solve the basic
                            problem statement of consolidating the information
                            for the public through the Mysuru Dasara website and
                            slowly countering other problem statements like Live
                            Streaming, Social media reach, Live coverage of
                            Mysuru Dasara andEntry management system.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div
                  className=" mx-auto mx-md-0 rounded overflow-hidden mt-md-n4 mb-4 mb-md-0 wow fadeInLeft"
                  data-wow-delay="0.5s"
                >
                  <div className="container">
                    {/* <span
                    className="d-block wow bounceInUp font-weight-500"
                    data-wow-delay="0.6s"
                    style={{ fontWeight: 500, color: "white" }}
                  >
                    {" "}
                    Dasara 2021 Live stream
                  </span> */}
                    {/* <iframe width="100%" height="400" src="https://www.youtube.com/embed/SqEYU1mly48" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                     {/*<div className="d-flex">
                      { <iframe width="100%" height="400" src="http://139.84.131.142:8080/17b07466-63ce-449c-a232-c5e239f849cf.html" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> }
                          <div class="text-center"><h5 >watch Yuva Dasara live</h5></div>
                       { <iframe width="100%" height="400" src="https://media.mysoredasara.gov.in/5b642a8e-a3d4-475c-b432-ce64093dc3dd.html" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> }
                    </div> */}
                    <img
                      width="895px"
                      height="620px"
                      className="img-fluid"
                      src={Assets.DASARA_PALACE}
                      alt="Dasara palace"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row align-items-md-center mt-20"
              style={{ paddingRight: "140px" }}
            >
              <div className="col-12">
                <div className="extraWrap pl-5 pl-xl-0 ml-xl-n4">
                  <ul className="list-unstyled position-relative pt-8 mb-0">
                    <li>
                      <div
                        className="elStoryColumn position-relative wow bounceInUp"
                        data-wow-delay="1s"
                      >
                        <div className="descrWrap">
                          <h1
                            className="escHeading font-weight-bold mb-4"
                            style={{ color: "#3F89BD" }}
                          >
                            About Fusion Minds
                          </h1>
                          <p style={{ color: "#B4B3B3" }}>
                            FusionMinds Technologies has been providing
                            technology services to various organisations and
                            agencies like Karnataka MilkFederation, MYMUL,
                            KOMUL, Sandesh The Prince Hotel, Strings Ecommerce,
                            Jalmahal Resorts, Mysuru Dasara Exhibition
                            Ticketingsystem, Mysuru Railway Parking Management
                            System and so many more.
                          </p>
                           {/*  <div
                            className="row align-items-md-center mt-15"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                height: "184px",
                                width: "302px",
                                backgroundColor: "#212121",
                              }}
                            >
                              <p style={{ color: "#5B5B5B" }}>client logos</p>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                height: "184px",
                                width: "302px",
                                backgroundColor: "#212121",
                              }}
                            >
                              <p style={{ color: "#5B5B5B" }}>client logos</p>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                height: "184px",
                                width: "302px",
                                backgroundColor: "#212121",
                              }}
                            >
                              <p style={{ color: "#5B5B5B" }}>client logos</p>
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                height: "184px",
                                width: "302px",
                                backgroundColor: "#212121",
                              }}
                            >
                              <p style={{ color: "#5B5B5B" }}>client logos</p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              className="row align-items-md-center"
              style={{ paddingRight: "140px" }}
            >
              <div className="col-12">
                <div className="extraWrap pl-5 pl-xl-0 ml-xl-n4">
                  <ul className="list-unstyled position-relative pt-8 mb-0">
                    <li>
                      <div
                        className="elStoryColumn position-relative wow bounceInUp"
                        data-wow-delay="1s"
                      >
                        <div className="descrWrap">
                          <h1
                            className="escHeading font-weight-bold mb-4"
                            style={{ color: "#3F89BD" }}
                          >
                            Mysore dasara website
                          </h1>
                          <p style={{ color: "#B4B3B3" }}>
                            The Mysuru Dasara website is developed with
                            information, design and content in mind. While the
                            website provides most of the first hand information,
                            it does not compromise the streaming experience at
                            all.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              className="row align-items-md-center mt-20"
              style={{ paddingRight: "140px" }}
            >
              <div className="col-12 col-md-6">
                <div className="extraWrap pl-5 pl-xl-0 ml-xl-n4">
                  <ul className="list-unstyled position-relative pt-8 mb-0">
                    <li>
                      <div
                        className="elStoryColumn position-relative wow bounceInUp"
                        data-wow-delay="1s"
                      >
                        <div className="">
                          <h1
                            className="escHeading font-weight-bold mb-4"
                            style={{ color: "#3F89BD" }}
                          >
                            Live streaming
                          </h1>
                          <p style={{ color: "#B4B3B3" }}>
                            Be it multiple locations, drone shots, crane shots,
                            FusionMinds has delivered the best scale of teams to
                            handle multiple locations parallelly. The streams
                            were the basis of almost every broadcast channel in
                            the world. Covering Annual General body meetings to
                            World famous Mysuru Dasara, FusionMinds has come a
                            long way!
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 mt-10">
                <div
                  className=" mx-auto mx-md-0 rounded overflow-hidden mt-md-n4 mb-4 mb-md-0 wow fadeInLeft"
                  data-wow-delay="0.5s"
                >
                  <div className="container d-flex align-items-center justify-content-center">
                    <img
                      className="img-fluid"
                      src={Assets.DASARA_BACKGROUND_ECLIPSE_1}
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        height: "561px",
                      }}
                    />
                    <img
                      className="img-fluid"
                      src={Assets.DASARA_MOBILE_1}
                      style={{
                        position: "absolute",
                        zIndex: 2,
                        width: "662px",
                        height: "306px",
                      }}
                    />
                    <img
                      className="img-fluid"
                      src={Assets.DASARA_MOBILE_BACKGROUND}
                      style={{
                        position: "absolute",
                        zIndex: 3,
                        width: "250px",
                        height: "250px",
                        paddingBottom: "40px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row align-items-md-center mt-50"
              style={{ paddingRight: "140px" }}
            >
              <div className="col-12 col-md-6">
                <div
                  className=" mx-auto mx-md-0 rounded overflow-hidden mt-md-n4 mb-4 mb-md-0 wow fadeInLeft"
                  data-wow-delay="0.5s"
                >
                  <div className="container d-flex align-items-center justify-content-center">
                    <img
                      className="img-fluid"
                      src={Assets.DASARA_BACKGROUND_ECLIPSE_2}
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        height: "600px",
                      }}
                    />
                    <img
                      className="img-fluid"
                      src={Assets.DASARA_MOBILE_2}
                      style={{
                        position: "absolute",
                        zIndex: 2,
                        height: "500px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="extraWrap pl-5 pl-xl-0 ml-xl-n4">
                  <ul className="list-unstyled position-relative pt-8 mb-0">
                    <li>
                      <div
                        className="elStoryColumn position-relative wow bounceInUp"
                        data-wow-delay="1s"
                      >
                        <div className="">
                          <h1
                            className="escHeading font-weight-bold mb-4"
                            style={{ color: "#3F89BD" }}
                          >
                            Ticketing Application
                          </h1>
                          <p style={{ color: "#B4B3B3" }}>
                            We have provided ticketing and entry management
                            systems to agencies/organizations of all sizes. We
                            have efficiently mangement the entry and exit of
                            vehicles, attendies from and to different places. We
                            provide the most sophisticated and simplified
                            solutions to facilitate the same accross various
                            domains and ecosystems.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </article>
    </section>
  );
}

export default Story;
