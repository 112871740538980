import React from "react";
import { useHistory } from "react-router-dom";
import Assets from "../Assets";

const HeaderComp = (props) => {
  const history = useHistory();

  return (
    <header id="pageHeader" className="headerFixer">
      <div className="container">
        <div className="position-relative">
          <div className="row">
            <div className="col-6 col-md-3">
              {/* logo */}
              <div className="logo">
                <a href="/">
                  <img
                    src={Assets.LOGO}
                    className="img-fluid"
                    alt="Archik Technologies"
                  />
                </a>
              </div>
            </div>
            <div className="col-6 col-md-9 position-static">
              {/* pageNav */}
              <nav
                id="pageNav"
                className="navbar navbar-expand-md navbar-dark rounded-0 border-0 px-0 pt-0 pt-md-2 pb-0 justify-content-end position-static"
              >
                {/* pgNavOpener */}
                <button
                  className="navbar-toggler pgNavOpener position-relative p-0 border-0 shadow-none rounded-0 mt-2"
                  type="button"
                  data-toggle="collapse"
                  data-target="#pageMainNavCollapse"
                  aria-controls="pageMainNavCollapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  style={{ outline: "none" }}
                >
                  {/* icnBar */}
                  <span className="icnBar position-absolute">
                    <span className="sr-only">menu</span>
                  </span>
                </button>
                {/* pageMainNavCollapse */}
                <div
                  className="collapse navbar-collapse justify-content-md-end pageMainNavCollapse"
                  id="pageMainNavCollapse"
                >
                  {/* pgMainNavigation */}
                  <ul className="navbar-nav text-uppercase pgMainNavigation font-weight-normal">
                    <li className="nav-item">
                      <a className="nav-link  smooth" href="#pageWrapper">
                        <div class="hap-nav-div">
                          <p>Home</p>
                        </div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link smooth" href="#about">
                        <div class="hap-nav-div">
                          <p>About</p>
                        </div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link smooth" href="#services">
                        <div class="hap-nav-div">
                          <p>Services</p>
                        </div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link smooth" href="#works">
                        <div class="hap-nav-div">
                          <p>Products</p>
                        </div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link smooth"
                        onClick={() => history.push("/dasara")}
                      >
                        <div class="hap-nav-div">
                          <p>Dasara</p>
                        </div>
                      </a>
                    </li>

                    <li className="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        data-toggle="dropdown"
                        href="#"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Policies and Conditions
                      </a>
                      <div class="dropdown-menu">
                        <a class="dropdown-item hap-nav-div" href="https://merchant.razorpay.com/policy/MtgO5FIOLEz3IW/privacy">
                          Privacy Policy
                        </a>
                        <a class="dropdown-item hap-nav-div" href="https://merchant.razorpay.com/policy/MtgO5FIOLEz3IW/privacy">
                          Terms & Conditions
                        </a>
                        <a class="dropdown-item hap-nav-div" href="https://merchant.razorpay.com/policy/MtgO5FIOLEz3IW/refund">
                          Refund & Processing
                        </a>
                        <div class="dropdown-divider hap-nav-div"></div>
                        <a class="dropdown-item" href="https://merchant.razorpay.com/policy/MtgO5FIOLEz3IW/shipping">
                          Shipping and Delivery
                        </a>
                        <a class="dropdown-item hap-nav-div" href="https://merchant.razorpay.com/policy/MtgO5FIOLEz3IW/contact_us">
                          Contact US(Payments Assistance)
                        </a>
                      </div>
                    </li>
                    {/* <li className="nav-item">
                         <a className="nav-link smooth" href="#blog">
                         <div  class="hap-nav-div"><p>Blog</p></div>
                           </a>
                       </li> */}
                    <li className="nav-item">
                      <a className="nav-link smooth" href="#contact">
                        <div class="hap-nav-div">
                          <p>Contact</p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderComp;
