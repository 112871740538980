import React from "react";
import Assets from "../../../components/Assets";

function Platform(props) {
  return (
    <section className="platformBlock bg-dark text-white pt-7 pb-4 pt-md-10 pb-md-7 pt-lg-16 pb-lg-12 pt-xl-23 pb-xl-18">
      <div className="container">
        <div className="container">
          <header className="text-center textSizeMedium mb-10 mb-md-16">
            <h2 className="text-white mb-5 wow fadeInUp text-capitalize archik-intro-headTitle">
              You have a problem statement?
            </h2>
            <p
              className="wow fadeInUp text-uppercase archik-sub-heading2"
              data-wow-delay="0.7s"
            >
              <small>
                While you tell us your problem statement over a coffee, we will
                give you a solution by the time you finish it.<br/> To reach us
                :
              </small>
            </p>
          </header>
          <div className="row justify-content-center">
            {/* <PlatformColumn
              icon={Assets.WHATSAPP_ICON}
              platform="WhatsApp"
              platformAddress="+91 90192 08342"
              delay="1.5s"
              Cref="https://wa.me/+919019208342"
            />
            <PlatformColumn
              icon={Assets.INSTAGRAM_ICON}
              platform="Instagram"
              platformAddress="Archik Technologies"
              delay="2s"
              Cref="https://instagram.com/archiktechnologies"
            />
            <PlatformColumn
              icon={Assets.LINKEDIN_PLATFORM_ICON}
              platform="Linkedin"
              platformAddress="Archik Technologies"
              delay="3.5s"
              Cref="https://www.linkedin.com/company/archiktechnologies"
            /> */}
            <PlatformColumn
              icon={Assets.FB_PLATFORM_ICON}
              platform="Facebook"
              platformAddress="Fusion Minds Technologies & Archik Technologies"
              delay="2.5s"
              Cref="https://www.facebook.com/fusionmindstechnologies/"
            />
            {/* <PlatformColumn
              icon={Assets.TWEET_PLATFORM_ICON}
              platform="Twitter"
              platformAddress="Archik Technologies"
              delay="3s"
              Cref="https://twitter.com/archiktech"
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
}

const PlatformColumn = ({ icon, platform, platformAddress, delay, Cref }) => {
  return (
    <div className="col-12 col-sm-6 col-md-4 d-table colXlDiv5">
      <a href={Cref ? Cref : "#"} target="blank" className="archik-content">
        {/* pfbColumn */}
        <aside
          className="pfbColumn bg-archik position-relative w-100 text-white rounded-lg pt-10 px-4 pb-4 mb-6 wow fadeInUp"
          data-wow-delay="3s"
        >
          <div className="position-absolute  rounded-circle d-flex align-items-center justify-content-center text-white icn wow bounceIn archik-platform-icon">
            <img src={icon} alt="Platforms" />
          </div>
          <strong className="title d-block font-weight-normal mb-1 archik-sub-heading">
            {platform}
          </strong>
          <span className="d-block fwMedium" >
            <a
              href={Cref ? Cref : "#"}
              target="blank"
              className="archik-content"
              style={{fontSize:"13px"}}
            >
              {platformAddress}
            </a>
          </span>
        </aside>
      </a>
    </div>
  );
};

export default Platform;
