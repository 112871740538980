import React from "react";
import Assets from "../../../components/Assets";
import Dot from "../../../components/home/Dot";
import Typical from "react-typical";

function Intro(props) {
  return (
    <article className="introBlock w-100 d-flex ">
      <div className="container-fluid archik-main-container">
        <div className="vAlign d-flex w-100 align-items-sm-center ">
          <div className="xAlign w-100 pt-15 pt-md-28 pt-lg-40 pb-xl-24 pb-md-20 pt-xl-41 pb-xl-30 ">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-10">
                  <div className="position-relative ibCaptionHolder">
                    {/* <strong
                      className="wow fadeInLeft archik-intro-sub"
                      data-wow-delay="2s"
                    >
                      DUMMY
                    </strong> */}
                    {/* <h1 className="text-capitalize md-12"> */}
                    <span
                      className="d-block h2 text-white wow headingTitle fadeInLeft archik-intro-headTitle bounceIn"
                      // data-wow-delay="2.5s"
                    >
                      Why risk your experiment <br/>while we can assess your risk?
                    </span>
                    <p
                      className="wow fadeInLeft mt-5 archik-sub-heading2"
                      data-wow-delay="2.8s"
                      style={{height:"50px"}}
                    >
                      <Typical
                        steps={["It works best", 1000, "It works best when its simple but better when the complexities are handled by us!", 1000]}
                        loop={Infinity}
                        wrapper="p"
                      />
                      
                    </p>
                    <p
                      className="wow fadeInLeft mt-6 archik-intro-sub3"
                      data-wow-delay="2.8s"
                    >
                      Your business is the right dish to which we add <br/> the right
                      ingredient of software!
                    </p>
                    <div className="ibBtnsWrap d-flex flex-wrap">
                      <a
                        href="#services"
                        data-wow-delay="3.3s"
                        className="text-white position-relative wow  bounceIn btnTheme archik-intro-btn1"
                        data-hover="Hire Me"
                      >
                        <span className="d-block btnText text-uppercase  archik-intro-btn1-text">
                          Our Services
                        </span>
                      </a>
                      {/* <a
                        href="#services"
                        data-wow-delay="3.3s"
                        className="text-white position-relative wow  bounceIn btnTheme mx-6 archik-intro-btn1"
                        data-hover="Hire Me"
                      >
                        <span className="d-block btnText  text-uppercase archik-intro-btn1-text">
                          Team
                        </span>
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 position-static">
                  <ul
                    className="list-unstyled  socialNetworks ibSocialNetworks mb-0 d-none d-md-block wow fadeIn  mt-xl-20 mt-lg-15"
                    data-wow-delay="4s"
                  >
                    <li>
                      <a
                        href="https://www.facebook.com/fusionmindstechnologies/"
                        target="_blank"
                      >
                        <img src={Assets.FACEBOOK_ICON} alt="Archik Facebook" />
                      </a>
                    </li>
                    {/* <li>
                          <a href="https://www.facebook.com/archikteam.t" target="_blank">
                           <img src={Assets.INSTAGRAM_ICON} alt="Archik Instagram" />
                          </a>
                        </li>  
                       <li>
                          <a href="https://www.facebook.com/archikteam.t" target="_blank">
                           <img src={Assets.WHATSAPP_ICON} alt="Archik Whatsapp" />
                          </a>
                        </li> 
                   <li>
                      <a href="https://twitter.com/OArchik" target="_blank">
                        <img src={Assets.TWITTER_ICON} alt="Archik Twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/archik-technologies"
                        target="_blank"
                      >
                        <img src={Assets.LINKEDIN_ICON} alt="Archik LinkedIn" />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <span className="vline wow vline1 position-absolute d-none d-md-block" />
      <span className="vline wow vline2 position-absolute d-none d-md-block" />
      <span className="vline wow vline3 position-absolute d-none d-md-block" />
      <span className="vline wow vline4 position-absolute d-none d-md-block" /> */}
    </article>
  );
}

export default Intro;
