import React,{ useState } from "react";
import Assets from "../../../components/Assets";
import * as ReactDOM from 'react-dom';

function Contact(props) {
  const [name, setName] = useState('');

  const handleForm = (e) =>{
    // alert(e.target);
    console.log(e.target);
  }
  return (
    <aside className="footerAside bg-archik pt-6 pb-6 pt-md-10 pb-md-8 pt-lg-16 pb-lg-13 pt-xl-24 pb-xl-18 wow fadeIn">
      <div id="contact">
        <div className="container">
          <div className="row container">
            <div className="col-12 col-md-6 col-lg-5 col-xl-4">
              {/* extraWrap */}
              <div className="extraWrap pr-lg-6 pr-xl-12 mb-5 mb-md-0">
                {/* ftLogo */}
                <div className="ftLogo mb-7 mb-lg-12">
                  <a href="#">
                    <img src={Assets.LOGO} className="img-fluid" alt="Archik" />
                  </a>
                </div>
                {/* <strong className="h5 d-block mb-4">Archik <span className="text-white">Technologies</span></strong>
                  <p>Address</p> */}
                <div>
                  <img
                    src={Assets.NAVIGATION_ICON}
                    alt="archik address"
                    className="rounded-circle archik-contact-nav px-3 py-2 "
                  />
                </div>

                <p className="mt-10">
                 <b>Fusion Minds Private Limited </b><br/> 
                  {/* 2nd Floor 5th Main Road  KSTRC Layout Chikkalasandra , Uttarahalli Main Road Bangalore - 560061 Ph - +91 80 4370 5593 */}
                 <span style={{fontSize:"14px"}}>G6, Buviiya Siri,</span>
                 <span style={{fontSize:"14px"}}> 2<sup>nd</sup> Floor, </span><br/>
                 <span style={{fontSize:"14px"}}>5<sup>th</sup> Main Road, KSRTC Layout,</span><br/>
                 <span style={{fontSize:"14px"}}>Chikkalasandra Uttarahalli Main Road</span><br/>
                 <span>BENGALURU - 560 061.</span>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-7 col-xl-8">
              <header className="mb-6 mb-lg-10">
                <h2 className="text-capitalize">
                  {/* headingTitle */}
                  <strong className="fwMedium d-block text-white position-relative headingTitle text-capitalize mb-3 archik-sub-heading archik-sub-headTitle">
                    ANY QUESTION?
                  </strong>
                  <span className="d-block font-weight-bold text-white archik-intro-headTitle">
                    Drop Us A Line
                  </span>
                </h2>
              </header>
              
                <div className="messageForm">
                  <form
                    id="contactForm"
                    data-toggle="validator"
                    // onSubmit={props.sendMail()}
                  >
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="form-group position-relative">
                          <input
                            id="name"
                            type="text"
                            name="user_name"
                            className="form-control w-100 d-block archik-contact-inp"
                            onChange={handleForm}
                            required
                            data-error="NEW ERROR MESSAGE"
                          />
                          <label className="labelAbsolute mb-0 font-weight-normal position-absolute  pl-2">
                            Name
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group position-relative">
                          <input
                            id="email"
                            type="email"
                            name="from_email"
                            className="form-control w-100 d-block archik-contact-inp"
                            required
                            data-error="NEW ERROR MESSAGE"
                          />
                          <label className="labelAbsolute mb-0 font-weight-normal position-absolute pl-2">
                            Email
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group position-relative">
                          <input
                            type="sub"
                            id="subject"
                            name="subject"
                            className="form-control w-100 d-block archik-contact-inp"
                            required
                            data-error="NEW ERROR MESSAGE"
                          />
                          <label className="labelAbsolute mb-0 font-weight-normal position-absolute pl-2">
                            Subject
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group position-relative">
                          <input
                            type="tel"
                            id="mobile_num"
                            name="mobile_num"
                            className="form-control w-100 d-block archik-contact-inp"
                            required
                            data-error="NEW ERROR MESSAGE"
                          />
                          <label className="labelAbsolute mb-0 font-weight-normal position-absolute pl-2">
                            Phone
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group position-relative">
                          <textarea
                            id="message"
                            name="message"
                            className="form-control w-100 d-block textareaInput archik-contact-inp"
                            required
                            data-error="NEW ERROR MESSAGE"
                            defaultValue={""}
                          />
                          <label className="labelAbsolute mb-0 font-weight-normal position-absolute pl-2">
                            Message
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div id="msgSubmit" className="form-message hidden" />
                    </div>
                    <button
                      id="form-submit"
                      type="submit"
                      className="btn btnThemeOutline position-relative text-uppercase btnMinSmall mt-4 mt-lg-8 archik-btn"
                      data-hover="Send Message"
                    >
                      <span className="d-block btnText">Send Message</span>
                    </button>
                  </form>
                  
                </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default Contact;
